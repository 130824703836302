import http from '@/utils/request'

/**
 * 贡献者信息
 * @returns
 */
export const contributorDetail = () => {
  return http.get('/contributor/detail')
}

/**
 * 贡献者申请
 * @returns
 */
export const applyContributor = (data = {}) => {
  return http.post('/contributor/apply', data)
}
