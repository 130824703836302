<template>
  <div class="container" v-if="!isLoding">
    <div class="apply" v-if="!contributor.status">
      <van-cell-group inset :border="false">
        <van-field
          center
          label-align="right"
          label-width="75"
          maxlength="20"
          autosize
          v-model="formData.code"
          label="贡献者码"
          right-icon="question-o"
          @click-right-icon="
            showHelper(
              '什么是贡献者码？',
              '自定义您的贡献者码，后续推广发放此码，他人使用您的贡献者码注册游玩游戏，您可以获得奖励，贡献者码内容可以是英文字母或者数字，请勿使用中文，长度不超过20个字，提交后无法修改，请谨慎填写！例子：laotouhuan123'
            )
          "
          placeholder="请输入您的贡献者码"
        />
        <van-field
          center
          maxlength="255"
          label-align="right"
          label-width="75"
          v-model="formData.home_page"
          label="个人主页"
          right-icon="question-o"
          @click-right-icon="showHelper('什么是个人主页？', '个人主页网址可以是直播间网址或者视频up主个人主页网址,必须以http://或者https://开头。')"
          placeholder="请输入您的个人主页网址"
        />
        <div class="upload-cell">
          <div class="upload-title">
            <span class="title-left" style="width: 75px">证明截图</span>
            <span style="font-size: 12px">（需要提供up主的账号截图，以证明此账号属于您，最多3张）</span>
          </div>
          <div class="upload-list">
            <van-uploader
              upload-icon="photo-o"
              multiple
              :max-count="3"
              :max-size="1024 * 1024 * 10"
              @oversize="oversize"
              v-model="uploadFiles"
              :after-read="afterRead"
            />
          </div>
        </div>
      </van-cell-group>

      <van-button block :disabled="subDisabled" style="margin-top: 15px" color="var(--app-button-bg-color)" @click="submit">提交审核</van-button>
    </div>

    <div class="apply-res" v-if="contributor.status == 1">
      <div class="message warning">
        <van-icon name="clock-o" size="100" />
        <div class="message-content">您已提交了贡献者申请，请耐心等待后台审核~</div>
      </div>
    </div>

    <div class="apply-res" v-if="contributor.status == 2">
      <div class="message danger">
        <van-icon name="warning-o" size="100" />
        <div class="message-content">
          {{ contributor.remark ? `您的贡献者审核未通过:${contributor.remark}` : '您的贡献者审核未通过' }}
          <div class="action">
            <van-button color="var(--app-button-bg-color)" @click="reapply">重新申请</van-button>
          </div>
        </div>
      </div>
    </div>

    <div class="apply-res" v-if="contributor.status == 3">
      <div class="message success">
        <van-icon name="passed" size="100" />
        <div class="message-content">
          您的贡献者审核通过，您的贡献者码为：
          <div class="other-data">{{ contributor.code }}</div>
        </div>
      </div>
    </div>

    <div class="apply-res" v-if="contributor.status == 4">
      <div class="message danger">
        <van-icon name="warning-o" size="100" />
        <div class="message-content">{{ contributor.remark ? `您的贡献者已被停用:${contributor.remark}` : '您的贡献者已被停用' }}</div>
        <div class="action">
          <van-button color="var(--app-button-bg-color)" @click="contactService">联系客服</van-button>
        </div>
      </div>
    </div>

    <van-dialog class="subscribe-dialog" v-model:show="showHelperDialog" confirm-button-text="关闭" :title="helperTitle">
      <div class="mode-content">
        <div class="model-help">{{ helperMessage }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'ContributorApply',
}
</script>
<script setup>
import { Field, CellGroup, Cell, Button, Uploader, Icon, Toast, Picker, Popup, Dialog } from 'vant'
import { ref, computed, onMounted, reactive } from 'vue'
import tool from '@/utils/tool'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { applyContributor, contributorDetail } from '@/api/contributor'
import { contactService } from '@/utils/common'
import { bindSteamConfirm, bindMobileConfirm } from '@/utils/user'
import { getQiniuToken, uploadFile } from '@/utils/upload'

const VanCell = Cell
const VanField = Field
const VanCellGroup = CellGroup
const VanButton = Button
const VanUploader = Uploader
const VanPicker = Picker
const VanPopup = Popup
const VanIcon = Icon
const VanDialog = Dialog.Component
const store = useStore()
const router = useRouter()

const isLoding = ref(true)
const showHelperDialog = ref(false)
const subDisabled = ref(false)
const helperTitle = ref('')
const helperMessage = ref('')
const uploadFiles = ref([])
const contributor = ref({})
const formData = reactive({ id: 0, code: '', home_page: '', project: 'tui7', account_pictures: [] })

onMounted(() => {
  initData()
})

const initData = async () => {
  getQiniuToken()
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')

  let res = await contributorDetail()

  if (res.data.contributor) {
    contributor.value = res.data.contributor
  }
  isLoding.value = false
}

const user = computed(() => store.state.user.info)
const playerExtend = computed(() => store.state.player.extend)

const checkBindSteamAndMobile = () => {
  if (!user.value.player_id) {
    bindSteamConfirm('申请贡献者')
    return false
  }

  if (!playerExtend.value.mobile) {
    bindMobileConfirm('申请贡献者')
    return false
  }

  return true
}

const validate = () => {
  if (!checkBindSteamAndMobile()) {
    return false
  }

  if (!formData.code) {
    Toast('请输入您的贡献者码')
    return false
  }

  let reg = /^[A-Za-z0-9]+$/
  if (!reg.test(formData.code)) {
    Toast('输入的贡献者码格式不正确,必须为数字或者英文字母')
    return false
  }

  if (!formData.home_page) {
    Toast('请输入个人主页网址')
    return false
  }

  reg = /^((https|http)?:\/\/)[^\s]+/
  if (!reg.test(formData.home_page)) {
    Toast('输入的个人主页网址不正确,必须是http://或者https://开头的网址')
    return false
  }

  if (!formData.project) {
    Toast('请选择游戏')
    return false
  }
  if (!uploadFiles.value.length) {
    Toast('请上传证明截图')
    return false
  }
  return true
}

const submit = async () => {
  if (!validate()) {
    return false
  }

  formData.account_pictures.length = 0
  uploadFiles.value.forEach(item => {
    formData.account_pictures.push(item.path)
  })

  let res = await applyContributor(formData)
  if (res) {
    Toast.success({
      message: res.message,
      forbidClick: true,
      onClose: () => {
        router.replace({ name: 'UserIndex' })
      },
    })
  }
}

const oversize = file => {
  Toast('图片大小不能超过10MB')
}

const afterRead = file => {
  uploadFile(file, res => {
    subDisabled.value = false
  })
}
const showHelper = (title, message) => {
  showHelperDialog.value = true
  helperTitle.value = title
  helperMessage.value = message
}
const reapply = () => {
  contributor.value.status = 0
  formData.id = contributor.value.id
  formData.code = contributor.value.code
  formData.home_page = contributor.value.home_page
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}

.upload-cell {
  color: #b0b4c9;
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
}

.upload-list {
  padding: 10px 5px;
}

.mode-box {
  border-radius: 10px;
  width: 90vw;
  background: #232329;
  overflow: hidden;
}

.mode-title {
  padding: 5px 15px;
  background: #373a44;
  color: #d3d7e5;
  font-size: 14px;
}

.mode-content {
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #b0b4c9;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.model-help {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.mode-content img {
  border-radius: 10px;
  margin: 15px 0;
  width: 100%;
}
.upload-title {
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
}
.upload-title .title-left {
  text-align: right;
  display: inline-block;
  margin-right: var(--van-field-label-margin-right);
}
.message {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.message.danger {
  color: #ee0a24;
}
.message.success {
  color: #07c160;
}
.message.warning {
  color: #07c160;
}
.message .van-icon {
  padding: 40% 0 30px;
}
.message-content {
  text-align: center;
  width: 100%;
  color: #fff;
}
.message .action {
  margin-top: 20px;
}
.message .other-data {
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 20px 10px;
  color: #3a9aed;
  font-weight: bold;
  font-size: 20px;
  line-height: 40x;
  text-align: center;
  background: #212429;
  word-break: break-all;
}
</style>
